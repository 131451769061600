import { Component, ElementRef, Inject, HostListener, ViewChild, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { WINDOW } from '@ng-toolkit/universal';

@Component({
    selector: 'app-scroll-top',
    templateUrl: './scroll-top.component.html',
    styleUrls: ['./scroll-top.component.less']
})

export class ScrollTopComponent {
    @ViewChild('scrollToTop') scrollButtonElt: ElementRef;
    private fadeDuration: number;
    private scrollDuration: number;
    private windowScrolled: boolean;

    constructor(
        @Inject(WINDOW) private window: Window,
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2) {
        this.fadeDuration = 0.08;
        this.scrollDuration = 700;
        this.windowScrolled = false;
    }

    @HostListener('window:scroll', [])
    onScroll() {
        if (this.window.pageYOffset >= 900 && !this.windowScrolled) {
            this.windowScrolled = true;
            this.fadeIn(this.scrollButtonElt);
        } else if (this.window.pageYOffset < 900 && this.windowScrolled) {
            this.windowScrolled = false;
            this.fadeOut(this.scrollButtonElt);
        }
    }

    private fadeIn(element: ElementRef) {
        let opacity = this.fadeDuration;
        this.renderer.setStyle(element.nativeElement, 'display', 'block');

        const TIMER = setInterval(() => {
            if (opacity >= 1) {
                clearInterval(TIMER);
            }

            this.renderer.setStyle(element.nativeElement, 'opacity', opacity);
            this.renderer.setStyle(element.nativeElement, 'filter', `alpha(opacity=${opacity} * 100)`);

            opacity += opacity * this.fadeDuration;
        }, 10);
    }

    private fadeOut(element: ElementRef) {
        let opacity = 1;

        const TIMER = setInterval(() => {
            if (opacity <= this.fadeDuration) {
                clearInterval(TIMER);
                this.renderer.setStyle(element.nativeElement, 'display', 'none');
            }

            this.renderer.setStyle(element.nativeElement, 'opacity', opacity);
            this.renderer.setStyle(element.nativeElement, 'filter', `alpha(opacity=${opacity} * 100)`);

            opacity -= opacity * this.fadeDuration;
        }, 10);
    }

    public scroll() {
        if (document.scrollingElement.scrollTop !== 0) {
            const duration = this.scrollDuration < 0 ? 0 : this.scrollDuration;
            const cosParameter = document.scrollingElement.scrollTop / 2;
            let scrollCount = 0;
            let oldTimestamp = null;

            const step = (newTimestamp: null | number) => {
                if (oldTimestamp !== null) {
                    scrollCount += Math.PI * (newTimestamp - oldTimestamp) / duration;

                    if (scrollCount >= Math.PI) {
                        return document.scrollingElement.scrollTop = 0;
                    }

                    document.scrollingElement.scrollTop = cosParameter + cosParameter * Math.cos(scrollCount);
                }

                oldTimestamp = newTimestamp;
                this.window.requestAnimationFrame(step);
            };

            this.window.requestAnimationFrame(step);
        }
    }
}
