import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, LOCALE_ID, OnInit, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})

export class FooterComponent implements OnInit {
  @ViewChild('contactModal') contactModalElt: ElementRef;

  public contactForm: FormGroup;
  public contactFormIsSubmitted = false;
  public copyright: string;
  public currentPath = '/';

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(LOCALE_ID) public localeId: string,
    private router: Router,
    private renderer: Renderer2,
    private formBuilder: FormBuilder,
    private httpClient: HttpClient) {
    const createdYear = 2020;
    const currentYear = new Date().getFullYear();
    this.copyright = createdYear !== currentYear ? `${createdYear} - ${currentYear}` : `${createdYear}`;
  }

  ngOnInit() {
    // exposes url into currentPath
    this.router.events.subscribe((_: NavigationEnd) => {
      if (typeof _.url !== 'undefined') {
        this.currentPath = _.url;
      }
    });

    // contact form
    this.contactForm = this.formBuilder.group({
      surname: ['', [Validators.minLength(4)]],
      name: ['', [Validators.required, Validators.minLength(4)]],
      email: ['', [Validators.required, Validators.email]],
      message: ['', [Validators.required, Validators.maxLength(4000)]],
      rgpd: ['', [Validators.requiredTrue]],
      newsletter: ['', []]
    });
  }

  onContactSubmit() {
    this.contactFormIsSubmitted = true;

    if (this.contactForm.invalid) {
      return;
    } else {
      this.sendPostRequest({
        language: this.localeId,
        data: [
          { name: 'surname', value: this.contactForm.value.surname },
          { name: 'name', value: this.contactForm.value.name },
          { name: 'email', value: this.contactForm.value.email },
          { name: 'message', value: this.contactForm.value.message },
          { name: 'rgpd', value: this.contactForm.value.rgpd },
          { name: 'newsletter', value: this.contactForm.value.newsletter }
        ]
      }).subscribe({
        next: () => {
          this.contactForm.reset();
          this.renderer.addClass(this.contactModalElt.nativeElement, 'confirmation');
        },
        error: (error) => {
          console.error(error);
          // Todo : what should be done if there is an error?
        }
      });
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.contactForm.controls; }

  // http post request
  private sendPostRequest(data: any): Observable<any> {
    return this.httpClient.post<any>('/contactus', data);
  }
}
