<header>
    <nav #nav>
        <div class="nav-wrapper">
            <a routerLink="/" class="brand-logo">
                <h1><img src="assets/img/header/logo_PYGUARD.svg" alt="PyGuard" class="lazyload"></h1>
            </a>
            <!-- burger menu -->
            <a href="#" data-target="menu-mobile" class="button-collapse sidenav-trigger" #sidenavTrigger>
                <div id="nav-icon" class="material-icon">
                    <span></span><span></span><span></span>
                </div>
            </a>
            <!-- top menu -->
            <ul class="menu right hide-on-small-only">
                <li routerLinkActive="activeItem"><a routerLink="/projet-pyguard">
                        <p i18n>Le projet</p>
                    </a></li>
                <li routerLinkActive="activeItem"><a routerLink="/donnees-personnelles">
                        <p i18n>Données personnelles</p>
                    </a></li>
                <!-- <li routerLinkActive="activeItem"><a routerLink="/blog">
                        <p i18n>Blog</p>
                    </a></li> -->
                <li routerLinkActive="activeItem"><a routerLink="/a-propos">
                        <p i18n>À propos</p>
                    </a></li>
                <li class="lang {{localeId}}">
                    <a *ngIf="localeId === 'fr'" href="{{currentPath}}" internal>
                        <p>{{localeId}}</p>
                    </a>
                    <a *ngIf="localeId !== 'fr'" href="/{{localeId}}{{currentPath}}" internal>
                        <p>{{localeId}}</p>
                    </a>

                    <ng-template ngFor let-lang [ngForOf]="languages">
                        <a *ngIf="lang.code !== localeId && lang.code === 'fr'" href="{{currentPath}}" internal>
                            <p>{{lang.code}}</p>
                        </a>
                        <a *ngIf="lang.code !== localeId && lang.code !== 'fr'" href="/{{lang.code}}{{currentPath}}" internal>
                            <p>{{lang.code}}</p>
                        </a>
                    </ng-template>
                </li>
                <span *ngIf="['/projet-pyguard', '/donnees-personnelles', '/a-propos'].indexOf(currentPath) !== -1" id="selector"></span>
            </ul>
        </div>
    </nav>
    <!-- sidenav menu -->
    <ul class="sidenav" id="menu-mobile" #sidenav>
        <li routerLinkActive="activeItem"><a routerLink="/projet-pyguard" class="sidenav-close">
                <p i18n>Le projet</p>
            </a></li>
        <li routerLinkActive="activeItem"><a routerLink="/donnees-personnelles" class="sidenav-close">
                <p i18n>Données personnelles</p>
            </a></li>
        <!-- <li routerLinkActive="activeItem"><a routerLink="/blog" class="sidenav-close">
                <p i18n>Blog</p>
            </a></li> -->
        <li routerLinkActive="activeItem"><a routerLink="/a-propos" class="sidenav-close">
                <p i18n>À propos</p>
            </a></li>
        <li class="lang {{localeId}}">
            <ng-template ngFor let-lang [ngForOf]="languages">
                <a *ngIf="lang.code !== localeId && lang.code === 'fr'" href="{{currentPath}}" internal>
                    <p>{{lang.label}}</p>
                </a>
                <a *ngIf="lang.code !== localeId && lang.code !== 'fr'" href="/{{lang.code}}{{currentPath}}" internal>
                    <p>{{lang.label}}</p>
                </a>
            </ng-template>
        </li>
    </ul>
</header>
