import { isPlatformServer } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';

import { WebglService } from '../_core/services/webgl.service';
import { WebpService } from '../_core/services/webp.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})

export class AppComponent {
  private kTestImages = {
    lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
    lossless: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
    alpha: 'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
    animation: 'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA'
  };

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private webglService: WebglService,
    private webpService: WebpService
  ) {
    // tslint:disable-next-line: no-floating-promises
    (async () => {
      if (await this.webpIsSupported()) {
        this.webpService.setChecked(true);
      } else {
        this.webpService.setChecked(false);
      }
    })();

    // tslint:disable-next-line: no-floating-promises
    (async () => {
      if (await this.webglIsSupported()) {
        this.webglService.setChecked(true);
      } else {
        this.webglService.setChecked(false);
      }
    })();
  }

  async webglIsSupported(): Promise<boolean> {
    // skip if server side rendering
    if (isPlatformServer(this.platformId)) {
      return true;
    }

    try {
      const canvas = document.createElement('canvas');
      const gl = canvas.getContext('webgl')
        || canvas.getContext('experimental-webgl');

      return (gl && gl instanceof WebGLRenderingContext);
    } catch (error) {
      return false;
    }
  }

  async webpIsSupported(): Promise<boolean> {
    // skip if server side rendering
    if (isPlatformServer(this.platformId)) {
      return true;
    }

    try {
      const results = await Promise.all([
        this.check_webp_feature('lossy'),
        this.check_webp_feature('lossless'),
        this.check_webp_feature('alpha'),
        this.check_webp_feature('animation')
      ]);

      return results.reduce((mask: boolean, currentValue: boolean) => mask && currentValue);
    } catch (error) {
      return false;
    }
  }

  async check_webp_feature(feature: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.onload = () => {
        resolve((img.width > 0) && (img.height > 0));
      };

      img.onerror = () => {
        reject(false);
      };

      img.src = 'data:image/webp;base64,' + this.kTestImages[feature];
    });
  }
}
