import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgtUniversalModule } from '@ng-toolkit/universal';
import { CookieService } from 'ngx-cookie-service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AnalyticsModule } from './components/analytics/analytics.module';
import { FooterModule } from './components/footer/footer.module';
import { HeaderModule } from './components/header/header.module';
import { ScrollTopModule } from './components/scroll-top/scroll-top.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    NgtUniversalModule,
    AppRoutingModule,
    HeaderModule,
    FooterModule,
    ScrollTopModule,
    AnalyticsModule,
    HttpClientModule
  ],
  providers: [
    CookieService,
    Title
  ],
  bootstrap: [
    AppComponent
  ]
})

export class AppModule { }
