import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostListener, Inject, LOCALE_ID, OnInit, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { WINDOW } from '@ng-toolkit/universal';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})

export class HeaderComponent implements OnInit, AfterViewInit {
  @ViewChild('nav') navElt: ElementRef;
  @ViewChild('sidenav') sidenavElt: ElementRef;
  @ViewChild('sidenavTrigger') sidenavTriggerElt: ElementRef;

  private availablesLanguages = [
    { code: 'fr', label: 'Français' },
    { code: 'en', label: 'English' }
  ];

  public languages = [];
  public currentPath = '/';

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(LOCALE_ID) public localeId: string,
    @Inject(WINDOW) private window: Window,
    private renderer: Renderer2,
    private router: Router
  ) {
    this.availablesLanguages.forEach(lang => {
      if (lang.code === LOCALE_ID.toString()) {
        this.languages.unshift(lang);
      } else {
        this.languages.push(lang);
      }
    });
  }

  ngOnInit() {
    // exposes url into currentPath
    this.router.events.subscribe((_: NavigationEnd) => {
      if (typeof _.url !== 'undefined') {
        this.currentPath = _.url;
      }
    });
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      import('materialize-css').then((materialize) => {
        materialize.Sidenav.init(
          this.sidenavElt.nativeElement,
          {
            onOpenStart: () => {
              this.renderer.removeClass(this.sidenavTriggerElt.nativeElement, 'closed');
              this.renderer.addClass(this.sidenavTriggerElt.nativeElement, 'opened');
            },
            onCloseEnd: () => {
              this.renderer.removeClass(this.sidenavTriggerElt.nativeElement, 'opened');
              this.renderer.addClass(this.sidenavTriggerElt.nativeElement, 'closed');
            }
          }
        );
      }, (error) => {
        console.error(`Can't load materialize-css:\n${error}`);
      });
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    if (this.window.pageYOffset >= 100) {
      this.renderer.addClass(this.navElt.nativeElement, 'scrolled-nav');
    } else {
      this.renderer.removeClass(this.navElt.nativeElement, 'scrolled-nav');
    }
  }
}
