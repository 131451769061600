import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { NewsletterComponent } from './components/newsletter/newsletter.component';
import { TranslationComponent } from './components/translations/translations.component';

import { ClickDisabledDirective, ClickResetDirective } from './directives/click/clickDisabled.directive';
import { ClickStopPropagationDirective } from './directives/click/clickStopPropagation.directive';
import { ExternalLinkDirective } from './directives/externalLinks/externalLink.directive';
import { InternalLinkDirective } from './directives/internalLinks/internalLink.directive';
import { MailToDirective } from './directives/mailto/mailto.directive';
import { CollapsibleDirective } from './directives/materialize/collapsible/collapsible.directive';
import { ModalDirective } from './directives/materialize/modal/modal.directive';
import { TabsDirective } from './directives/materialize/tabs/tabs.directive';
import { TooltipDirective } from './directives/materialize/tooltip/tooltip.directive';
import { TelDirective } from './directives/tel/tel.directive';
import { WebpDirective } from './directives/webp/webp.directive';
import { DocumentationComponent } from './components/documentation/documentation.component';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  declarations: [
    TranslationComponent,
    NewsletterComponent,
    ClickDisabledDirective,
    ClickResetDirective,
    ClickStopPropagationDirective,
    CollapsibleDirective,
    InternalLinkDirective,
    ExternalLinkDirective,
    MailToDirective,
    ModalDirective,
    TabsDirective,
    TelDirective,
    TooltipDirective,
    WebpDirective,
    DocumentationComponent
  ],
  exports: [
    CommonModule,
    NewsletterComponent,
    ClickDisabledDirective,
    ClickResetDirective,
    ClickStopPropagationDirective,
    CollapsibleDirective,
    InternalLinkDirective,
    ExternalLinkDirective,
    MailToDirective,
    ModalDirective,
    TabsDirective,
    TelDirective,
    TooltipDirective,
    WebpDirective,
    DocumentationComponent
  ]
})

export class SharedModule { }
