import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScrollTopComponent } from './scroll-top.component';
import { ScrollTopRoutingModule } from './scroll-top-routing.module';

import { SharedModule } from '../../../_shared/shared.module';

/**
 * Home module class
 *
 * @export
 */
@NgModule({
  declarations: [
    ScrollTopComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ScrollTopRoutingModule
  ],
  exports: [
    ScrollTopComponent
  ]
})

export class ScrollTopModule { }
