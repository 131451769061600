<footer>
  <ul id="sitemap">
    <li><a routerLink="/" i18n>Accueil</a></li>
    <li><a routerLink="/projet-pyguard" i18n>Le projet</a></li>
    <li><a routerLink="/donnees-personnelles" i18n>Données personnelles</a></li>
    <!-- <li><a routerLink="/blog" i18n>Blog</a></li> -->
    <li><a routerLink="/a-propos" i18n>À propos</a></li>
    <li><a routerLink="/faq" i18n>Faq</a></li>
    <li><a class="modal-trigger" href="#contactModal" i18n>Nous contacter</a></li>
    <li><a routerLink="/mentions-legales" i18n>Mentions légales</a></li>
  </ul>
  <div>
    <div>
      <p i18n>Suivez&#8209;nous&nbsp;:</p>
      <ul>
        <li>
          <a href="https://www.linkedin.com/company/pyguard/">
            <img src="assets/img/footer/linkedin.svg" alt="linkedin" class="lazyload" />
          </a>
        </li>
        <li>
          <a href="https://twitter.com/PyGuardFR">
            <img src="assets/img/footer/twitter.svg" alt="twitter" class="lazyload" />
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/PyGuard/">
            <img src="assets/img/footer/facebook.svg" alt="facebook" class="lazyload" />
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/channel/UCfw5aynjFE6GbVXtUi_kwsQ">
            <img src="assets/img/footer/youtube.svg" alt="youtube" class="lazyload" />
          </a>
        </li>
      </ul>
    </div>
    <button class="waves-effect btn-flat modal-trigger" href="#analyticsModal">
      <picture>
        <source type="image/webp" data-srcset="assets/img/analytics/cookie.webp">
        <source type="image/png" data-srcset="assets/img/analytics/cookie.png">
        <img data-src="assets/img/analytics/cookie.png" alt="cookie" class="lazyload" />
      </picture>
      <p i18n>Préférences de cookies</p>
    </button>
    <p i18n>&copy; Panga {{copyright}} - Tous droits réservés</p>
  </div>
</footer>

<span *ngIf="currentPath === '/'" class="fingerprint"></span>

<!-- Add "confirmation" class after form validation -->
<div id="contactModal" class="modal" #contactModal>
  <div id="contactForm" class="modal-content">
    <h4 i18n>Nous contacter</h4>
    <form [formGroup]="contactForm" (ngSubmit)="onContactSubmit()">
      <fieldset>
        <div class="input-field">
          <label for="surname" i18n>Prénom</label>
          <input class="validate" type="text" id="surname" name="surname" minlength="4" formControlName="surname">
          <span *ngIf="contactFormIsSubmitted && f.surname.errors && f.surname.errors.minlength" class="helper-text"
            data-error="Le prénom doit comporter au moins 4 caractères" i18n-data-error></span>
        </div>
        <div class="input-field">
          <label for="name" i18n>Nom *</label>
          <input class="validate" type="text" id="name" name="name" minlength="4" required="required"
            formControlName="name">
          <span *ngIf="contactFormIsSubmitted && f.name.errors && f.name.errors.minlength" class="helper-text"
            data-error="Le nom doit comporter au moins 4 caractères"></span>
        </div>
        <div class="input-field">
          <label for="email" i18n>Adresse email *</label>
          <input class="validate" type="email" id="email" name="email" required="required" formControlName="email">
          <span *ngIf="contactFormIsSubmitted && f.email.errors && f.email.errors.required" class="helper-text"
            data-error="L'adresse mail est requise" i18n-data-error></span>
          <span *ngIf="contactFormIsSubmitted && f.email.errors && f.email.errors.email" class="helper-text"
            data-error="L'adresse e-mail doit être valide" i18n-data-error></span>
        </div>
      </fieldset>
      <fieldset class="textarea">
        <div class="input-field">
          <label for="message" i18n>Message *</label>
          <textarea id="message" class="materialize-textarea validate" name="message" maxlength="2000"
            required="required" formControlName="message"></textarea>
          <span *ngIf="contactFormIsSubmitted && f.message.errors && f.message.errors.required" class="helper-text"
            data-error="Le message est requis" i18n-data-error></span>
          <span *ngIf="contactFormIsSubmitted && f.message.errors && f.message.errors.maxlength" class="helper-text"
            data-error="Le message peut contenir au plus 2000 caractères" i18n-data-error></span>
        </div>
      </fieldset>
      <fieldset>
        <p>
          <label>
            <input type="checkbox" class="filled-in validate" required formControlName="rgpd" />
            <span i18n>* En envoyant ce message, j'accepte que PANGA SAS conserve mes données envoyées afin
              de traiter ma demande, et uniquement dans ce but.
              Pour plus d'informations consultez notre <a href="https://www.personal-data-management-policy.panga.fr/#article2" i18n-href target="_blank">politique de gestion des&nbsp;données personnelles</a>
            </span>
            <span *ngIf="contactFormIsSubmitted && f.rgpd.errors && f.rgpd.errors.required" class="helper-text"
              data-error="Ce champ est requis" i18n-data-error="footer.contact.error.message.maxLength"></span>
          </label>
        </p>
        <p>
          <label>
            <input type="checkbox" class="filled-in" formControlName="newsletter" />
            <span i18n>Je souhaite m'abonner à la newsletter et accepte que mon adresse email soit utilisée
              pour me tenir au courant de l'avancement du projet PyGuard.</span>
          </label>
        </p>
      </fieldset>
      <fieldset>
        <input type="submit" class="normal-btn highlight-btn" value="ENVOYER" i18n-value>
      </fieldset>
    </form>
  </div>
  <div id="validationMessage" class="modal-content">
    <picture>
      <source type="image/webp" data-srcset="assets/img/footer/email_illu.webp" />
      <source type="image/png" data-srcset="assets/img/footer/email_illu.png" />
      <img data-src="assets/img/footer/email_illu.png" alt="confirmation" class="lazyload" />
    </picture>
    <h5 i18n>Message envoyé&nbsp;!</h5>
    <p i18n>Nous vous remercions pour votre message, et vous répondrons dans les plus brefs délais.</p>
    <p i18n>- L'équipe PyGuard</p>
  </div>
</div>

<!-- Add "confirmation" class after form validation -->
<div id="newsletterModal" class="modal" #newsletterModal>
  <div id="newsletterForm" class="modal-content">
    <h2 i18n>Suivre le projet</h2>
    <p i18n>Tenez-vous au courant de l'avancement de notre projet en vous abonnant à notre newsletter&nbsp;!
    </p>
    <app-newsletter></app-newsletter>
  </div>
  <div id="validation" class="modal-content">
    <picture>
      <source type="image/webp" data-srcset="assets/img/footer/email_illu.webp" />
      <source type="image/png" data-srcset="assets/img/footer/email_illu.png" />
      <img data-src="assets/img/footer/email_illu.png" alt="confirmation" class="lazyload" />
    </picture>
    <h5 i18n>Demande envoyée&nbsp;!</h5>
    <p i18n>Nous vous remercions de votre intérêt pour PyGuard. Un email vous demandant de vérifier votre adresse mail
      vient de vous être envoyé pour confirmer votre inscription. Il peut prendre quelques minutes à arriver, pensez à
      vérifier les courriers indésirables.</p>
    <p i18n>- L'équipe PyGuard</p>
  </div>
</div>
