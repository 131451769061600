import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

/**
 * Access to the global window variable.
 */
declare var window: {
  [key: string]: any;
  prototype: Window;
  new(): Window;
};

@Injectable({
  providedIn: 'root'
})

export class AnalyticsService {
  public renderer: Renderer2;
  public retain: boolean;
  private enabled: boolean;
  private consentGracePeriod: number;
  private trackingNode: HTMLScriptElement;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private cookieService: CookieService,
  ) {
    this.consentGracePeriod = 312; // 312 hours = 13 days
    this.enabled = false;
    this.retain = false;

    this.deleteTestCookies();
  }


  get isActivated(): boolean {
    return this.enabled;
  }

  public setStatus(status: boolean, retain?: boolean) {
    this.enabled = status;

    if (this.enabled) {
      this.enableTracking(retain);
      this.deleteTestCookies();
    } else {
      this.disableTracking(retain);
    }
  }

  public hasConsent(): boolean {
    return this.cookieService.check('mtm_consent');
  }

  public needUserAnswer(): boolean {
    const currentMSecsSinceEpoch = new Date().getTime();

    const consentCookieMSecsSinceEpoch = this.cookieService.get('mtm_consent');
    if (consentCookieMSecsSinceEpoch !== ''
      && currentMSecsSinceEpoch + this.consentGracePeriod * 60 * 60 * 1000 > parseInt(consentCookieMSecsSinceEpoch, 10)) {
      return false;
    }

    const noConsentCookieMSecsSinceEpoch = this.cookieService.get('mtm_consent_removed');
    if (noConsentCookieMSecsSinceEpoch !== ''
      && currentMSecsSinceEpoch + this.consentGracePeriod * 60 * 60 * 1000 > parseInt(noConsentCookieMSecsSinceEpoch, 10)) {
      return false;
    }

    return true;
  }

  /**
   * Logs a visit to this page
   * @param title title of the visited page
   */
  public trackPageView(title: string): void {
    if (this.enabled) {
      const args: any[] = [];
      args.push(title);
      window._paq.push(['trackPageView', ...args]);
    }
  }

  /**
   * Logs an event with an event category (Videos, Music, Games…), an event action (Play, Pause, Duration,
   * Add Playlist, Downloaded, Clicked…), and an optional event name and optional numeric value.
   * @param category Category of the event
   * @param action Action of the event
   * @param name Optional name of the event
   * @param value Optional value for the event
   */
  public trackEvent(category: string, action?: string, name?: string, value?: number): void {
    if (this.enabled) {
      const args: any[] = [category, action];

      if (name) {
        args.push(name);
      }

      if (typeof value === 'number') {
        args.push(value);
      }

      window._paq.push(['trackEvent', ...args]);
    }
  }

  public trackLink(url: string, linkType: string): void {
    if (this.enabled) {
      window._paq.push(['trackLink', url, linkType]);
    }
  }

  private enableTracking(retain: boolean): void {
    this.trackingNode = this.renderer.createElement('script');
    const trackingCode = this.renderer.createText(`
      var _paq = window._paq || [];
      _paq.push(['requireConsent']);
      _paq.push(['trackPageView']);
      _paq.push(["setDocumentTitle", document.domain + "/" + document.title]);
      _paq.push(["setCookieDomain", "*.www.py-guard.fr"]);
      _paq.push(["setDomains", ["*.www.py-guard.fr"]]);
      _paq.push(['enableLinkTracking']);
      (function () {
          var u = "https://analytics.panga.fr/";
          _paq.push(['setTrackerUrl', u + 'matomo.php']);
          _paq.push(['setSiteId', '9']);
          var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
          g.type = 'text/javascript'; g.async = true; g.defer = true; g.src = u + 'matomo.js'; s.parentNode.insertBefore(g, s);
          g.onload = function() {
              _paq.push(['setConsentGiven']);
              ${retain ? '_paq.push([\'rememberConsentGiven\', ' + this.consentGracePeriod + ']);' : ''}

              (function() {
                  var cookies = document.cookie.split(';');
                  for (var i=0; i<cookies.length; i++) {
                      if (cookies[i].startsWith('_pk_testcookie')) {
                          document.cookie = cookies[i] + ';expires=Thu, 01 Jan 1970 00:00:01 GMT;'
                      }
                  }
              })();
          };
      })();
    `);

    this.renderer.appendChild(this.trackingNode, trackingCode);
    this.renderer.appendChild(this.document.head, this.trackingNode);
  }

  private disableTracking(retain: boolean): void {
    if (typeof this.trackingNode !== 'undefined') {
      this.renderer.removeChild(this.document.head, this.trackingNode);
    }

    this.deleteAllCookies();

    if (retain) {
      this.cookieService.set(
        'mtm_consent_removed',
        new Date().getTime().toString(),
        new Date(new Date().getTime() + this.consentGracePeriod * 60 * 60 * 1000),
        '/',
        undefined,
        undefined,
        'Lax');
    }
  }

  private deleteTestCookies(): void {
    for (const key in this.cookieService.getAll()) {
      if (key.startsWith('_pk_testcookie.')) {
        this.cookieService.delete(key, '/');
      }
    }
  }

  private deleteAllCookies(): void {
    for (const key in this.cookieService.getAll()) {
      if (key.startsWith('_pk') || key.startsWith('mtm_consent')) {
        this.cookieService.delete(key, '/');
      }
    }
  }
}
