import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, Inject, OnDestroy, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

// tslint:disable-next-line: directive-selector
@Directive({ selector: '.modal' })

export class ModalDirective implements AfterViewInit, OnDestroy {
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private router: Router,
    private modalElt: ElementRef) { }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      import('materialize-css').then((materialize) => {
        const instance = materialize.Modal.init(this.modalElt.nativeElement);

        this.router.events.subscribe((_: NavigationEnd) => {
          if (typeof _.url !== 'undefined' && instance.id !== 'analyticsModal') {
            instance.close();
          }
        });
      }, (error) => {
        console.error(`Can't load materialize-css :\n${error}`);
      });
    }
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      import('materialize-css').then((materialize) => {
        const instance = materialize.Modal.getInstance(this.modalElt.nativeElement);
        if (instance.id !== 'analyticsModal') {
          instance.destroy();
        }
      }, (error) => {
        console.error(`Can't load materialize-css :\n${error}`);
      });
    }
  }
}
