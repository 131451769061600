import { Injectable } from '@angular/core';
import { AsyncSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class WebglService {
  private supported: AsyncSubject<boolean>;

  constructor() {
    this.supported = new AsyncSubject<boolean>();
  }

  public setChecked = (supported: boolean): void => {
    this.supported.next(supported);
    this.supported.complete();
  }

  public isSupported = (): Observable<boolean> => {
    return this.supported.asObservable();
  }
}
