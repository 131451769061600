import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { FooterComponent } from './footer.component';
import { FooterRoutingModule } from './footer-routing.module';

import { SharedModule } from '../../../_shared/shared.module';

/**
 * Home module class
 *
 * @export
 */
@NgModule({
  declarations: [
    FooterComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    FooterRoutingModule
  ],
  exports: [
    FooterComponent
  ]
})

export class FooterModule { }
