import { AfterViewInit, Directive, ElementRef, HostListener/*, HostBinding, Input,*/ } from '@angular/core';

import { AnalyticsService } from '../../../_core/services/analytics.service';
import { PathService } from '../../../_core/services/path.service';

/**
 * Note :
 * The selector isn't working properly. The double negation or startwith (^) seems to be unused
 * So, insteed of @HostBinding and @Input, the constructor do the job.
 */


@Directive({
  // tslint:disable-next-line: directive-selector
  // selector: 'a:not([routerLink]):not([href^="#"])'

  // tslint:disable-next-line: directive-selector
  selector: 'a:not([routerLink])'
})

export class ExternalLinkDirective implements AfterViewInit {
  // @HostBinding('rel')
  // @Input()
  // rel = 'noopener';

  // @HostBinding('target')
  // @Input()
  // target = '_blank';

  private elt: ElementRef;

  constructor(
    private analyticsService: AnalyticsService,
    private pathService: PathService,
    elt: ElementRef
  ) {
    this.elt = elt;
  }

  ngAfterViewInit() {
    if (this.elt.nativeElement.hasAttribute('href')
      && !this.elt.nativeElement.getAttribute('href').startsWith('#')
      && !this.elt.nativeElement.hasAttribute('internal')
    ) {
      this.elt.nativeElement.setAttribute('rel', 'noopener');
      this.elt.nativeElement.setAttribute('target', '_blank');
    }
  }

  @HostListener('click') onClick() {
    if (this.elt.nativeElement.hasAttribute('href')
      && !this.elt.nativeElement.getAttribute('href').startsWith('#')
      && !this.elt.nativeElement.hasAttribute('internal')
    ) {
      this.analyticsService.trackLink(this.elt.nativeElement.getAttribute('href'), 'external');
    } else {
      this.analyticsService.trackPageView(this.pathService.path);
    }
  }
}
