import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';

import { AnalyticsService } from '../../../_core/services/analytics.service';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.less']
})

export class AnalyticsComponent implements AfterViewInit {
  @ViewChild('modalAnalytics') modalElt: ElementRef;

  public retain: boolean; // view binding

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private analyticsService: AnalyticsService,
    private renderer: Renderer2
  ) {
    this.analyticsService.renderer = this.renderer;
    this.retain = false;
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      import('materialize-css').then((materialize) => {
        const instance = materialize.Modal.getInstance(this.modalElt.nativeElement);
        instance.options.preventScrolling = false;
        instance.options.opacity = 0;
        instance.options.dismissible = false;

        if (!instance.isOpen && this.analyticsService.needUserAnswer()) {
          instance.open();
        } else if (this.analyticsService.hasConsent()) {
          this.analyticsService.setStatus(true);
        }
      }, (error) => {
        console.error(`Can't load materialize-css:\n${error}`);
      });
    }
  }

  public onClose(consent: boolean): void {
    this.analyticsService.setStatus(consent, this.retain);
  }
}
