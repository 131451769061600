import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('../home/home.module').then(mod => mod.HomeModule),
    pathMatch: 'full',
  },
  {
    path: 'projet-pyguard',
    loadChildren: () => import('../project/project.module').then(mod => mod.ProjectModule)
  },
  {
    path: 'donnees-personnelles',
    loadChildren: () => import('../personalData/personalData.module').then(mod => mod.PersonalDataModule)
  },
  // {
  //   path: 'blog',
  //   loadChildren: () => import('../blog/blog.module').then(mod => mod.BlogModule)
  {
    path: 'a-propos',
    loadChildren: () => import('../about/about.module').then(mod => mod.AboutModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('../faq/faq.module').then(mod => mod.FaqModule)
  },
  {
    path: 'mentions-legales',
    loadChildren: () => import('../legalNotice/legalNotice.module').then(mod => mod.LegalNoticeModule)
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      initialNavigation: 'enabled',
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled'
    })
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
