import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HeaderRoutingModule } from './header-routing.module';
import { HeaderComponent } from './header.component';

import { SharedModule } from '../../../_shared/shared.module';

/**
 * Home module class
 *
 * @export
 */
@NgModule({
  declarations: [
    HeaderComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    HeaderRoutingModule
  ],
  exports: [
    HeaderComponent
  ]
})

export class HeaderModule { }
