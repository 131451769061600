<div id="analyticsModal" class="modal bottom-sheet" #modalAnalytics>
    <div class="modal-content">
        <div>
            <div class="header">
                <picture>
                    <source type="image/webp" data-srcset="assets/img/analytics/cookie.webp">
                    <source type="image/png" data-srcset="assets/img/analytics/cookie.png">
                    <img data-src="assets/img/analytics/cookie.png" alt="cookie" class="lazyload"/>
                </picture>
                <h4 i18n>Nous utilisons des&nbsp;cookies de&nbsp;mesure d'audience</h4>
            </div>
        </div>
        <p i18n>Ce&nbsp;site utilise des&nbsp;cookies de&nbsp;mesure d'audience. Vos&nbsp;données de&nbsp;navigation
            sur&nbsp;ce&nbsp;site sont&nbsp;envoyées à&nbsp;Panga&nbsp;SAS. Votre adresse IP et&nbsp;plus généralement
            toutes les&nbsp;informations susceptibles de&nbsp;permettre de&nbsp;vous identifier sont anonymisées afin
            de&nbsp;protéger votre&nbsp;vie&nbsp;privée.</p>
        <form>
            <label>
                <input type="checkbox" class="filled-in" [checked]="retain" (change)="retain = !retain"/>
                <span i18n>J'accepte qu'un&nbsp;cookie soit déposé pour se&nbsp;souvenir de&nbsp;mon&nbsp;choix</span>
            </label>
        </form>
        <a i18n href="https://www.personal-data-management-policy.panga.fr/#article2" target="_blank">Consulter notre politique de&nbsp;gestion des&nbsp;données personnelles</a>
    </div>
    <div class="modal-footer">
        <button i18n class="modal-close waves-effect btn-flat" (click)="onClose(false)">JE REFUSE</button>
        <button i18n class="modal-close waves-effect btn-flat" (click)="onClose(true)">J'ACCEPTE</button>
    </div>
</div>
