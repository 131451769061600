<form [formGroup]="newsletterForm" (ngSubmit)="onSubmit()" #form>
    <div class="form-group">
        <div class="input-field" #inputEmail>
            <input type="email" class="validate" formControlName="email" i18n-placeholder placeholder="Adresse mail" autocomplete="email" />
            <span *ngIf="submitted && f.email.errors && f.email.errors.required" class="helper-text" data-error="L'adresse mail est requise" i18n-data-error></span>
            <span *ngIf="submitted && f.email.errors && f.email.errors.email" class="helper-text" data-error="L'adresse e-mail doit être valide" i18n-data-error></span>
        </div>
        <input class="highlight-btn" type="submit" i18n-value value="SUIVRE LE PROJET" #submitButton />
    </div>
</form>
