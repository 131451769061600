import { PlatformLocation } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PathService {
  private currentPath: string;

  constructor(
    private platformLocation: PlatformLocation,
    private router: Router
  ) {
    this.currentPath = '/';
    this.router.events.subscribe((_: NavigationEnd) => {
      if (typeof _.url !== 'undefined') {
        this.currentPath = _.url;
      }
    });
  }


  get path(): string {
    return this.currentPath;
  }

  get hostname(): string {
    return this.platformLocation.hostname;
  }
}
