import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AnalyticsComponent } from './analytics.component';
import { AnalyticsRoutingModule } from './analytics-routing.module';

import { SharedModule } from '../../../_shared/shared.module';

/**
 * Home module class
 *
 * @export
 */
@NgModule({
  declarations: [
    AnalyticsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    AnalyticsRoutingModule
  ],
  exports: [
    AnalyticsComponent
  ]
})

export class AnalyticsModule { }
