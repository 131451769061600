import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, HostListener, Inject, Input, LOCALE_ID, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WINDOW } from '@ng-toolkit/universal';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.less']
})

export class NewsletterComponent implements AfterViewInit, OnInit {
  @ViewChild('form') formElt: ElementRef;
  @ViewChild('inputEmail') inputEmailElt: ElementRef;
  @ViewChild('submitButton') submitButtonElt: ElementRef;

  @Input() formHeight: string;
  @Input() firstInputMaxWidth: string;

  private initialSubmitValue: string;

  public tooltipOptions: M.TooltipOptions;
  public newsletterForm: FormGroup;
  public submitted: boolean;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) public localeId: string,
    @Inject(WINDOW) private window: Window,
    private renderer: Renderer2,
    private formBuilder: FormBuilder,
    private httpClient: HttpClient) {
    this.submitted = false;
    this.tooltipOptions = {
      exitDelay: 1500,
      enterDelay: 0,
      html: null,
      margin: 5,
      inDuration: 300,
      outDuration: 250,
      position: 'bottom',
      transitionMovement: 10
    };
  }

  ngOnInit() {
    this.newsletterForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  ngAfterViewInit() {
    // Apply attr
    if (this.firstInputMaxWidth !== null && this.firstInputMaxWidth !== undefined) {
      this.renderer.setStyle(this.inputEmailElt.nativeElement, 'max-width', this.firstInputMaxWidth);
    }

    if (this.formHeight !== null && this.formHeight !== undefined) {
      this.renderer.setStyle(this.formElt.nativeElement, 'height', this.formHeight);
    }

    this.initialSubmitValue = this.submitButtonElt.nativeElement.value;

    if (this.window.innerWidth < 400) {
      this.submitButtonElt.nativeElement.value = this.initialSubmitValue.split(' ')[0];
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.window.innerWidth < 400) {
      this.submitButtonElt.nativeElement.value = this.initialSubmitValue.split(' ')[0];
    } else {
      this.submitButtonElt.nativeElement.value = this.initialSubmitValue;
    }
  }

  onSubmit() {
    this.submitted = true;
    // If form is invalid, stop here, show errors
    if (this.newsletterForm.invalid) { return; }

    this.sendPostRequest({
      language: this.localeId,
      email: this.newsletterForm.value.email
    }).subscribe({
      next: (data) => {
        this.newsletterForm.reset();
        import('materialize-css').then((materialize) => {
          const modalElt = this.document.getElementById('newsletterModal');
          this.renderer.addClass(modalElt, 'confirmation');

          const modalInstance = materialize.Modal.getInstance(this.document.getElementById('newsletterModal'));
          modalInstance.open();
        }, (error) => {
          console.error(`Can't load materialize-css :\n${error}`);
        });
      },
      error: (error) => {
        // Todo : what should be done if there is an error?
        console.error(error);
      }
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.newsletterForm.controls; }

  // http post request
  private sendPostRequest(data: any): Observable<any> {
    return this.httpClient.post<any>('/subscribe', data);
  }
}
